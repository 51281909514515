// src/MessageList.js
import React from "react";
import "./MessageList.css";

const MessageList = ({ messages }) => {
  return (
    <div className="message-list">
      {messages.map((msg, index) => {
        // console.log(msg);
        if (msg[2] === "human") {
          return (
            <div key={index} className="message">
              <p className="Input User"> {msg[0]}</p>
              <p className="Fix User"> {msg[1]}</p>
            </div>
          );
        }
        return (
          <div key={index} className="messageBot">
            {/* <p className="Input User"> {msg[0]}</p> */}
            <p className="Bot"> {msg[1]}</p>
          </div>
        );
      })}
    </div>
  );
};

export default MessageList;
