// src/ChatApp.js
import React, { useState } from "react";
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";
import "./ChatApp.css";

const ChatApp = () => {
  const [messages, setMessages] = useState([]);

  const addMessage = (newMessages) => {
    console.log(newMessages);
    setMessages([...messages, ...newMessages]);
  };

  return (
    <div className="chat-app" style={{ width: "600px", height: "100vh" }}>
      <MessageList messages={messages} />
      <MessageInput addMessage={addMessage} />
    </div>
  );
};

export default ChatApp;
