import React, { useState, useEffect } from "react";
import "./MessageInput.css";

// Initialize SpeechRecognition API
const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = new SpeechRecognition();

recognition.continuous = false; // Stop automatically after one phrase
recognition.interimResults = false; // Get only final results
recognition.lang = "en-US"; // Set the language

const MessageInput = ({ addMessage }) => {
  const [input, setInput] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [checklist, setChecklist] = useState({
    grammarCheck: false,
    howItCanBeWrong: false,
    reply: false,
    enableSpeechSynthesizer: true,
  });

  // SpeechSynthesis - Speak a message
  const speakMessage = (text) => {
    if (!checklist.enableSpeechSynthesizer) {
      console.log("Speech synthesizer is disabled.");
      return;
    }
    console.log("Speaking message:", text);
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = "en-US";
    synth.speak(utterance);
  };

  const handleSubmit = async () => {
    try {
      console.log("Submitting input:", input);
      console.log("Checklist state:", checklist);

      const apiKey = "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";
      const messages = [];

      // Add checklist options to the messages array
      if (checklist.grammarCheck) {
        console.log("Adding Grammar Check to messages.");
        messages.push({
          role: "user",
          content: `Grammar check: "${input}"`,
        });
      }

      if (checklist.howItCanBeWrong) {
        console.log("Adding How It Can Be Wrong to messages.");
        messages.push({
          role: "user",
          content: `Explain how this can be wrong: "${input}"`,
        });
      }

      if (checklist.reply) {
        console.log("Adding Reply to messages.");
        messages.push({
          role: "user",
          content: `Reply to the message: "${input}"`,
        });
      }

      console.log("Messages to be sent:", messages);

      await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKey}`,
        },
        body: JSON.stringify({
          model: "gpt-4o-mini",
          max_tokens: 150,
          messages: messages,
        }),
      }).then((response) => {
        console.log("API response received:", response);
        response.json().then((data) => {
          console.log("Parsed API response:", data);
          const responses = data.choices.map(
            (choice) => choice.message.content
          );
          responses.forEach((response, index) => {
            const messageType = Object.keys(checklist)[index];
            console.log(`Adding message of type ${messageType}:`, response);
            addMessage([[input, response, messageType]]);
            speakMessage(response);
          });
          setInput("");
        });
      });
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      addMessage(["An error occurred while fetching data.", ""]);
    }
  };

  // Handle Speech Recognition result
  useEffect(() => {
    recognition.onresult = (event) => {
      const speechToText = event.results[0][0].transcript;
      console.log("Speech recognition result:", speechToText);
      setInput(speechToText);
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error detected: " + event.error);
      setIsListening(false);
    };

    // Automatically submit form after speech recognition ends
    recognition.onend = () => {
      console.log("Speech recognition ended.");
      setIsListening(false);
      if (input.trim() !== "") {
        handleSubmit(); // Automatically submit after recognition ends
      }
    };
  }, [input]);

  // Start listening to speech
  const startListening = () => {
    console.log("Starting speech recognition.");
    setIsListening(true);
    recognition.start();
  };

  return (
    <form onSubmit={(e) => e.preventDefault()} className="message-input">
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Type a message..."
      />
      <div className="checklist">
        <label>
          <input
            type="checkbox"
            checked={checklist.grammarCheck}
            onChange={() =>
              setChecklist({
                ...checklist,
                grammarCheck: !checklist.grammarCheck,
              })
            }
          />
          Grammar Check
        </label>
        <label>
          <input
            type="checkbox"
            checked={checklist.howItCanBeWrong}
            onChange={() =>
              setChecklist({
                ...checklist,
                howItCanBeWrong: !checklist.howItCanBeWrong,
              })
            }
          />
          How it can be wrong
        </label>
        <label>
          <input
            type="checkbox"
            checked={checklist.reply}
            onChange={() =>
              setChecklist({
                ...checklist,
                reply: !checklist.reply,
              })
            }
          />
          Reply
        </label>
        <label>
          <input
            type="checkbox"
            checked={checklist.enableSpeechSynthesizer}
            onChange={() =>
              setChecklist({
                ...checklist,
                enableSpeechSynthesizer: !checklist.enableSpeechSynthesizer,
              })
            }
          />
          Enable Speech Synthesizer
        </label>
      </div>
      <button type="submit" onClick={handleSubmit}>
        Send
      </button>
      <button type="button" onClick={startListening}>
        {isListening ? "Listening..." : "🎙️ Speak"}
      </button>
    </form>
  );
};

export default MessageInput;
